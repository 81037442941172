import { h } from 'vue';
const gct = document.createElement('canvas').getContext('2d');
const imageMap = new Map();

async function clipImage (source, clipRect, drawRect=new DOMRect(0, 0, clipRect.width, clipRect.height)) {
  gct.canvas.width = drawRect.width;
  gct.canvas.height = drawRect.height;
  gct.clearRect(0, 0, gct.canvas.width, gct.canvas.height);
  gct.drawImage(source, clipRect.x, clipRect.y, clipRect.width, clipRect.height, 0, 0, drawRect.width, drawRect.height);

  const blob = await new Promise(res => gct.canvas.toBlob(res));
  return URL.createObjectURL(blob);
}

/**
 * @param { HTMLImageElement } sprite 精灵图
 * @param { DOMRect } clipRect 裁剪矩形
 * @param { RawProps } props
 * @returns { VNode<HTMLImageElement> }
 * @description 从精灵图中创建图片
 */
export function createImageFromSprite(sprite, clipRect, {isCache=false, ...props} = {}) {
  const originalVnode = h('img', {
    ...props,
    onVnodeMounted (vnode) {
      if (isCache && imageMap.has(originalVnode)) {
        vnode.el.src = imageMap.get(originalVnode);
        return;
      }

      if (sprite.complete) {
        clipImage(sprite, clipRect).then(src => {
          vnode.el.src = src;
          isCache && imageMap.set(originalVnode, src);
        });
      } else {
        sprite.addEventListener('load', async () => {
          vnode.el.src = await clipImage(sprite, clipRect);
          isCache && imageMap.set(originalVnode, vnode.el.src);
        });
      }
    },

    onVnodeUnmounted (vnode) {
      !isCache && URL.revokeObjectURL(vnode.el.src);
    }
  });

  originalVnode.revoke = () => {
    URL.revokeObjectURL(imageMap.get(originalVnode));
    imageMap.delete(originalVnode);
  };

  return originalVnode;
}

export async function toObjectURL(source) {
  let src = '';

  if (source instanceof HTMLVideoElement) {
    src = await clipImage(
      source,
      new DOMRect(0, 0, source.videoWidth, source.videoHeight),
      new DOMRect(0, 0, source.clientWidth, source.clientHeight)
    );
  } else if (source instanceof HTMLImageElement) {
    src = await clipImage(
      source,
      new DOMRect(0, 0, source.clientWidth, source.clientHeight)
    );
  } else if (source instanceof HTMLCanvasElement) {
    src = URL.createObjectURL(await new Promise(res => gct.canvas.toBlob(res)));
  }

  return src;
}
