<template>
  <div class="home-container" :style="{ minWidth: viewStore.minViewWidth }">
    <landing-page :handleTryIt="handleTryIt" />
    <tool-collection ref="toolListRef"></tool-collection>
    <Footer></Footer>
    <a
      class="badge-featured"
      target="_blank"
      href="https://www.producthunt.com/posts/booltool?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-booltool"
    >
      <img
        width="250"
        height="54"
        style="width: 250px; height: 54px"
        src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=374723&theme=light&period=weekly&topic_id=268"
        alt="Booltool - AI&#0045;powered&#0032;toolkit&#0032;for&#0032;your&#0032;pic&#0032;editing&#0032;&#0038;&#0032;copywriting | Product Hunt"
      />
    </a>
    <Modal :visible="showModal" @closeModal="showModal = false" :toolName="currentToolName"></Modal>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import Footer from "@/layouts/components/footer/index.vue";
import toolCollection from './components/tool-collection.vue';
import landingPage from './components/landing-page.vue';
import Modal from './components/modal.vue';
import { eventTracking } from '@/utils/eventTracking.js';
import { showModal, currentToolName } from "./viewModel";
import { useViewStore } from '@/store';

const route = useRoute();
const viewStore = useViewStore();
const toolListRef = ref(null);

function scrollToAnchor() {
  toolListRef.value.root.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function handleTryIt() {
  eventTracking("booltool_homepage_tryit");
  scrollToAnchor();
}

onMounted(() => {
  viewStore.minViewWidth = '390px';

  if (route.query.utm_source) {
    sessionStorage.setItem("utm_source", route.query.utm_source)
  }

  if (route.query.ref) {
    sessionStorage.setItem("ref", route.query.ref)
  }

  eventTracking("booltool_homepage_view")
});
</script>
<style scoped>
.home-container {
  position: relative;
  overflow-x: scroll;
}

.badge-featured {
  position: fixed;
  left: 40px;
  bottom: 20px;
  z-index: 20;
}

@screen s {
  .badge-featured {
    display: none;
  }
}
</style>