import AdvancedVideo from '@/components/advanced-video.vue';
import { createImageFromSprite } from '@/utils/resource.js';
import avatarSpriteUrl from '@/assets/home/avatar_sprite.png';
import { toolsConfig } from "@/components/models/config.js";

const avatarSprite = new Image();
avatarSprite.src = avatarSpriteUrl;

const commonProps = {
  width: 84,
  height: 84,
  class: 'group-avatar',
  isCache: true,
}

export const groupList = [
  'Online Shop Owner',
  'Content Creator',
  'Digital Marketer',
  'Graphic Designer'
].map((name, i) => {
  const avatar = createImageFromSprite(avatarSprite, new DOMRect(i * 84, 0, 84, 84), { ...commonProps, key: i });
  const vnode = (
    <>
      { avatar }
      <span class='group-name'>{ name }</span>
    </>
  );

  return { vnode, revokeResource: avatar.revoke };
});

export const toolList = toolsConfig.map(({collection, children}) => {
  const list = [];

  for (const item of children) {
    let preview = null;

    if (item.thumbnail.endsWith('.mp4')) {
      preview = <AdvancedVideo class="preview" src={item.thumbnail} autoplay loop lazy/>
    } else {
      preview = <img class="preview" src={item.thumbnail}/>
    }

    list.push({
      title: item.title,
      description: item.description,
      disabled: item.disabled,
      trackName: item.trackName,
      path: item.path,
      preview,
    });
  }

  return { name: collection, list };
});