<template>
  <div class="modal-container" :style="{display: showModal ? '' : 'none'}">
    <!-- <Modal :visible="showModal" :zIndex="1010" :width="840" @cancel="handleCancel" class="home-modal" :bodyStyle="{marginBlock: '100px'}">
      
    </Modal> -->
    <div class="modal-content-container">
      <div class="modal-content">
        <img :src="closeIcon" class="close-icon" @click="handleCancel"/>
        <p class="modal-title">Enter your information to request a demo</p>
        <a-form :model="formState" class="home-form" name="basic" autocomplete="off" @finish="onFinish"
          @finishFailed="onFinishFailed">
          <!-- name -->
          <a-form-item name="name" class="mb-7" :validateStatus="errorFiles.name">
            <a-input v-model:value="formState.name" class="form-input" placeholder="Name" @change="checkField('name')">
              <template #prefix>
                <img :src="prefix_name" />
              </template>
            </a-input>
          </a-form-item>
          <!-- company -->
          <a-form-item name="company" class="mb-7" :validateStatus="errorFiles.company">
            <a-input v-model:value="formState.company" class="form-input" placeholder="Company"
              @change="checkField('company')">
              <template #prefix>
                <img :src="prefix_company" />
              </template>
            </a-input>
          </a-form-item>
          <!-- role -->
          <a-form-item name="role" class="mb-7" :validateStatus="errorFiles.role">
            <a-input v-model:value="formState.role" class="form-input" placeholder="Role" @change="checkField('role')">
              <template #prefix>
                <img :src="prefix_role" />
              </template>
            </a-input>
          </a-form-item>
          <!-- email -->
          <a-form-item name="email" class="mb-8" :validateStatus="errorFiles.email">
            <a-input v-model:value="formState.email" class="form-input" placeholder="Email" @change="checkField('email')">
              <template #prefix>
                <img :src="prefix_email" />
              </template>
            </a-input>
          </a-form-item>
          <!-- button -->
          <a-form-item>
            <boolv-btn type="primary" html-type="submit" :disabled="disabled">
              <a-spin :indicator="indicator" v-if="isLoading" />
              <span>Submit</span>
            </boolv-btn>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  h,
  ref,
  watch,
  reactive,
  defineProps,
  watchEffect,
  defineEmits,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import prefix_email from "@/assets/prefix_email.svg";
import prefix_name from "@/assets/user-name.svg";
import prefix_company from "@/assets/prefix_company.svg";
import prefix_role from "@/assets/prefix_role.svg";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { eventTracking } from "@/utils/eventTracking.js";
import closeIcon from '@/assets/icon_close.svg';

const emits = defineEmits(["closeModal"])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  toolName: {
    type: String,
    default: ''
  },
})

const showModal = ref(props.visible)
const instance = getCurrentInstance()

watchEffect(() => {
  showModal.value = props.visible;

  if (instance.subTree === null) return;
  if (showModal.value) {
    document.body.setAttribute(instance.subTree.scopeId, '');
  } else {
    document.body.removeAttribute(instance.subTree.scopeId);
  }
})

const formState = reactive({
  name: "",
  company: "",
  role: "",
  email: "",
})

const disabled = ref(true)
watch(formState, () => {
  if (formState.email && formState.name && formState.company && formState.role) {
    disabled.value = false;
  } else {
    disabled.value = true
  }
})

const errorFiles = reactive({
  name: "success",
  company: "success",
  role: "success",
  email: "success",
})

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '20px',
    color: '#fff',
    marginRight: '8px'
  },
  spin: true,
});

const checkEmail = (email) => {
  // /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  const reg = /^.*@.*$/;
  return reg.test(email)
}

const checkField = (field) => {
  if (field === 'name') {
    if (!formState.name) {
      errorFiles.name = "error"
    } else {
      errorFiles.name = "success"
    }
  } else if (field === 'company') {
    if (!formState.company) {
      errorFiles.company = "error"
    } else {
      errorFiles.company = "success"
    }
  } else if (field === 'role') {
    if (!formState.role) {
      errorFiles.role = "error"
    } else {
      errorFiles.role = "success"
    }
  } else if (field === 'email') {
    if (formState.email && checkEmail(formState.email)) {
      errorFiles.email = "success"
    } else {
      errorFiles.email = "error"
    }
  }
}

const checkForm = () => {
  let flag = true
  if (!formState.name) {
    errorFiles.name = "error"
    flag = false
  } else {
    errorFiles.name = "success"
  }
  if (!formState.company) {
    errorFiles.company = "error"
    flag = false
  } else {
    errorFiles.company = "success"
  }
  if (!formState.role) {
    errorFiles.role = "error"
    flag = false
  } else {
    errorFiles.role = "success"
  }
  //check email
  if (formState.email && checkEmail(formState.email)) {
    errorFiles.email = "success"
  } else {
    errorFiles.email = "error"
    flag = false
  }
  return flag
}

const isLoading = ref(false)
const onFinish = async () => {
  let flag = checkForm()
  if (flag) {
    isLoading.value = true
    await eventTracking("booltool_info_collect", {
      tool_name: props.toolName,
      name: formState.name,
      company: formState.company,
      role: formState.role,
      email: formState.email,
    })
    setTimeout(() => {
      isLoading.value = false
      handleCancel()
    }, 1000);
  } else {
    console.log('error')
  }

};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const handleCancel = () => {
  emits("closeModal")
}

onMounted(() => {
  if (showModal.value) {
    document.body.setAttribute(instance.subTree.scopeId, '');
  }
})

onBeforeUnmount(() => {
  document.body.removeAttribute(instance.subTree.scopeId)
})
</script>

<style lang="less" scoped>
body {
  overflow-y: hidden;
}

.modal-container {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1010;
  overflow-y: auto;
}

.home-modal {
  :global(.home-modal .ant-modal-content) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :global(.ant-input-prefix) {
    margin-left: 10px;
    margin-right: 13px;
  }
}

.modal-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-block: 80px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 48px);
  max-width: 840px;
  margin: 0 auto;
  padding: 70px 0px;
  background-color: #fff;
  border-radius: 4px;
}

.modal-title {
  padding-bottom: 36px;
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 16px;
  height: 16px;
  padding: 6px;
  box-sizing: content-box;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EDEDED;
  }
}

.home-form {
  width: 100%;
  max-width: 375px;
}

.form-input, .boolv-btn {
  width: 100%;
  min-width: auto;
}

.boolv-btn {
  height: 54px;
  box-sizing: border-box;
}

@screen s {
  .modal-content-container {
    position: relative;
    padding-block: 0;
    background-color: #fff;
    align-items: flex-start;
  }

  .modal-content {
    position: static;
    padding-block: 85px;
    border-radius: 0;
  }

  .modal-title {
    font-size: 24px;
    padding-bottom: 48px;
  }

  .boolv-btn {
    margin-top: 66px;
  }
}
</style>