<template>
  <div class="lading-page-view">
    <img class="landing-page-bg" :src="landingPageBg"/>
    <div class="content-container">
      <img class="ranking" :src="honor" height="45" alt="ranking" style="display: inline-block"/>
      <p class="web-title">All-In-One Suite of Amazing Tools for</p>

      <marquee class="group-list" :scrollTime="600" :delayTime="800" speed="ease" :handleAnimationEnd="handleAnimationEnd">
        <v-render :vnode="getGroupNode(groupIndex)"/>
        <template #hide-area>
          <v-render :vnode="getGroupNode(groupIndex + 1)" :key="getIndex(groupIndex + 1)"/>
        </template>
      </marquee>

      <p class="sub-title">
        Amazing pictures, stunning videos, and wonderful texts are all at your fingertips.
      </p>
      <p class="powered-by">
        Powered by <img :src="openaiIcon" height="20"/>
      </p>
      <button class="home-btn-primary" @click="props.handleTryIt">
        Try it now
      </button>
      <img class="preview fade-in" height="540" :src="langding_page" />
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted } from 'vue';
import marquee from '@/components/marquee.vue';
import landingPageBg from '@/assets/home/home_background.svg';
import honor from '@/assets/home/honor.png';
import openaiIcon from '@/assets/home/openai.svg';
import langding_page from '@/assets/home/landing_page.png';
import { groupList } from './data.config';

const groupIndex = ref(0);
const props = defineProps({
  handleTryIt: {
    type: Function,
    required: true
  }
});

/* --- start (跑马灯相关逻辑) --- */
function getIndex(i) {
  const length = groupList.length;
  return (i % length + length) % length;
}

function getGroupNode(i) {
  return groupList[getIndex(i)].vnode;
}

async function handleAnimationEnd() {
  groupIndex.value++;
}
/* --- end --- */

onUnmounted(() => {
  for (const { revokeResource } of groupList) {
    revokeResource();
  }
});
</script>

<style scoped>
.lading-page-view {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 135px 75px;
  background: linear-gradient(291.3deg, #632CFF 26.46%, #8E68FF 99.95%);
  color: #FFFFFF;
  line-height: 1.5;
}

.landing-page-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.content-container {
  @apply w-lv;
  position: relative;
  font-size: 18px;
}

.ranking {
  height: 44px;
}

.web-title {
  font-weight: 600;
  font-size: 60px;
  margin-block: 12px 30px;
}

.sub-title {
  margin-block: 24px;
}

.sub-title, .powered-by {
  font-weight: 500;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.9);
}

.powered-by > img {
  display: inline-block;
  height: 20px;
  margin-left: 8px;
}

.home-btn-primary {
  color: #FFFFFF;
  margin-block: 38px;
  width: 212px;
  height: 58px;
  background: linear-gradient(360deg, #000000 0%, #252525 100%);
  border-radius: 800px;
}

.home-btn-primary:hover {
  opacity: 0.8;
}

.category-name {
  font-weight: 500;
  font-size: 36px;
}

.preview {
  width: 1280px;
  margin-inline: auto;
}

.group-list:deep(.group-avatar) {
  display: inline-block;
  width: 85px;
  margin-right: 10px;
}

.group-list:deep(.group-name) {
  font-weight: 600;
  font-size: 56px;
  background: linear-gradient(270deg, #00FF57 0%, #00FFF0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.group-list:deep(> .x-marquee > .show-area), .group-list:deep(> .x-marquee > .hide-area) {
  display: flex;
  justify-content: center;
  align-items: center;;
}

.fade-in {
  opacity: 1;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media(orientation: landscape) {
  .lading-page-view {
    min-height: 100vh;
  }
}

@screen m {
  .content-container {
    @apply w-mv;
    font-size: 17px;
  }

  .ranking {
    height: 38px;
  }

  .powered-by > img {
    height: 18px;
  }

  .home-btn-primary {
    width: 183px;
    height: 50px;
    margin-block: 30px;
  }
}

@screen ms {
  .web-title, .group-list:deep(.group-name) {
    font-size: clamp(32px, 5vw, 60px);
  }

  .group-list:deep(.group-avatar) {
    width: clamp(50px, 7vw, 85px);
  }
}

@screen s {
  .web-title, .group-list:deep(.group-name) {
    font-size: 30px;
    font-weight: 500;
  }

  .lading-page-view {
    padding-block: 100px 60px;
  }

  .content-container {
    @apply w-sv;
    font-size: 16px;
  }

  .web-title {
    margin-block: 16px;
  }

  .ranking {
    height: 32px;
  }

  .sub-title {
    margin-block: 14px 10PX;
  }

  .powered-by > img {
    height: 16px;
  }

  .home-btn-primary {
    width: 154px;
    height: 42px;
    margin-block: 25px;
  }
}
</style>