<template>
  <div class="x-marquee-container">
    <div class="x-marquee" ref="marqueeRef">
      <div class="show-area">
        <slot></slot>
      </div>
      <div class="hide-area">
        <slot name="hide-area"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

let timer = null;
let animation = null;
const marqueeRef = ref(null);
const props = defineProps({
  delayTime: {
    type: Number,
    default: 500,
  },
  scrollTime: {
    type: Number,
    default: 500
  },
  speed: {
    type: String,
    default: 'linear',
  },
  handleAnimationEnd: {
    type: Function,
    default: () => {},
  },
});

async function handleAnimationEnd() {
  animation.pause();
  await props.handleAnimationEnd();
  timer = setTimeout(() => animation.play(), props.delayTime);
}

onMounted(() => {
  animation = new Animation(
    new KeyframeEffect(
      marqueeRef.value,
      [
        { transform: 'translateY(0)' },
        { transform: 'translateY(-100%)' }
      ],
      {
        duration: props.scrollTime,
        iterations: 1,
        easing: props.speed
      }
    ),
  );

  animation.onfinish = handleAnimationEnd;
  timer = setTimeout(animation.play.bind(animation), props.delayTime);
});

onBeforeUnmount(() => {
  clearTimeout(timer);
  animation.cancel();
});
</script>

<style scoped>
.x-marquee-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.x-marquee {
  position: relative;
}

.hide-area {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}
</style>